import "../scss/app.scss";
import Menu from './Menu';
import Whatsapp from './Whatsapp';
import Options from './Options';
import { connect } from 'react-redux';
import { Loading } from '../actions';
import { store } from '../store';
import Spinner from 'react-bootstrap/Spinner';


function App(props) {

    var loading = props.loading;

    if (loading === false) {

        setTimeout(function() {
            store.dispatch(Loading(true))
          }, 2700);


        return (
            <div id='home'>

                <img src="/images/logomrm.png" className="home__logo bounce-out-top" alt="" />

            </div>

        )


    } else {
        return (
            <div id='home'>
    
                <video autoPlay loop muted className="video">
                    <source src="/fundoclaro.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
    
    
                <Menu></Menu>
    
                <Options></Options>

                <Whatsapp></Whatsapp>
    
            </div>
        )
    }

  
}



function mapStateToProps(state) {
return {
    loading: state.loading
}
}

export default connect(
mapStateToProps
)(App);