import "../scss/contato.scss";
import Menu from './Menu';
import Footer from './Footer';

function Contato() {

    return (
        <div id='contato' style={{ backgroundImage: "url('../background3.png')" }}>


            <Menu></Menu>

            <div className='contato slide-in-bottom'>
                <h1 className="contato__title">Contato</h1>

                <a className="contato__link"  href="https://wa.me/5531999520766"><img className="contato__img" src="/whatsapp.png" alt="" /> (31) 9 9952 0766</a>

                <a className="contato__link"  href="operacional@mrmtransportes.com.br"><img className="contato__img" src="/email.png" alt="" /> operacional@mrmtransportes.com.br</a>
            </div>

            <Footer></Footer>


        </div>
    )
  
}


export default Contato