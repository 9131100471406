const servicos = [
    {
        nome: "Rodoviário Standard",
        categoria: "Transporte Rodoviário",
        tipo: "Cargas volumosas  fracionadas cujo a demanda de prazo seja estendida e flexíveis.",
        prazo_img: "/images/servicos/rodo1.png",
        prazo_texto: null,
        url: 'rodoviario',
        capa: "../images/servicos/1.png"
    },
    {
        nome: "Rodoviário Flash",
        categoria: "Transporte Rodoviário",
        tipo: "Cargas volumosas fracionadas cujo a demanda de prazo de entrega, necessite de urgência maior do que no serviço tradicional rodoviário e menor do que no serviço aéreo. Atende também cargas urgentes que tenham restrição de transporte aéreo.",
        prazo_img: "/images/servicos/rodo2.png",
        prazo_texto: null,
        url: 'rodoviario',
        capa: "../images/servicos/2.png"
    },
    {
        nome: "Rodoviário Prime",
        categoria: "Transporte Rodoviário",
        tipo: "Cargas com alto nível de prioridade, com inviabilidade de utilização de modal de transporte aéreo.",
        prazo_texto: "O prazo varia de acordo com a região de destino, podendo se estender em caso de postos fiscais, greves e intemperes etc.",
        prazo_img: null,
        url: 'rodoviario',
        capa: "../images/servicos/9.png"
    },
    {
        nome: "Grains Express",
        categoria: "Transporte Rodoviário",
        tipo: "Oferecemos transporte rodoviário especializado em grãos, com frota moderna e rastreamento em tempo real para garantir que sua carga chegue ao destino com agilidade e total segurança. Nossa equipe é treinada para assegurar a integridade dos grãos durante todo o percurso, respeitando prazos e normas de qualidade. Conte com a gente para soluções eficientes no transporte de grãos!",
        prazo_img: "/images/servicos/graos.png",
        prazo_texto: null,
        url: 'rodoviario',
        capa: "../images/servicos/7.png"
    },
    {
        nome: "MRM Heavy",
        categoria: "Transporte Especiais Pesados",
        tipo: "Cargas com alto nível de prioridade, com inviabilidade de utilização de modal de transporte aéreo.",
        prazo_img: "/images/servicos/heavy.png",
        prazo_texto: null,
        url: 'especiais',
        capa: "../images/servicos/5.png"
    },
    {
        nome: "MRM Transfer",
        categoria: "Modalidade Transferência",
        tipo: "Transferência de cargas entre Centros de Distribuição",
        prazo_texto: "O prazo varia de acordo com a região de destino, podendo se estender em caso de postos fiscais, greves e intempéries etc.",
        prazo_img: null,
        url: 'transferencia',
        capa: "../images/servicos/3.png"
    },
    {
        nome: "Aéreo Padrão",
        categoria: "Transporte Aéreo",
        tipo: "Cargas volumosas / ideal para cargas com prazos de entrega flexíveis.",
        prazo_texto: "O prazo varia entre 24h e 96h após a coleta.",
        prazo_img: null,
        url: 'aereo',
        capa: "../images/servicos/6.png"
    },
    {
        nome: "Próximo Vôo",
        categoria: "Transporte Aéreo",
        tipo: "Geralmente carga leve ou fracionada em poucos volumes. Seu valor é  altamente agregado, seja nominalmente ou pela necessidade em si de cumprimento de prazos.",
        prazo_texto: "O prazo varia entre 24h e 96h após a coleta.",
        prazo_img: null,
        url: 'aereo',
        capa: "../images/servicos/8.png"
    },
    {
        nome: "Aéreo Express",
        categoria: "Transporte Aéreo",
        tipo: "Cargas volumosas / ideal para cargas com prazos de entrega flexíveis.",
        prazo_texto: "Entre 24h e 72h para as principais capitais.",
        prazo_img: null,
        url: 'aereo',
        capa: "../images/servicos/10.png"
    },
]

export default servicos;