import servicos from "../servicos";

export const CheckMobile = (value) => async dispatch => {

    dispatch({ type: 'CHECK_MOBILE', payload: value });
};

export const OpenCloseMenu = (value) => async dispatch => {

    dispatch({ type: 'OPEN_CLOSE_MENU', payload: value });
};

export const GetServiços = (value) => async dispatch => {

    const filtered= servicos.filter(
        (servico) => servico.url === value
    );

    console.log(filtered);

    dispatch({ type: 'GET_SERVIÇOS', payload: filtered });
};

export const Loading = (value) => async dispatch => {

    console.log('ok')

    dispatch({ type: 'LOADING', payload: value });
};