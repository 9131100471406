import "../scss/footer.scss";

function Whatsapp() {

    return (

        <a target="blank" rel="noreferrer" href="https://wa.me/5531999520766"><img id="whatsapp" src="/whatsapp-logo.png" alt="" /></a>

    )
  
}


export default Whatsapp