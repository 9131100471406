import "../scss/sobre.scss";
import Menu from './Menu';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Footer from './Footer';
import Whatsapp from './Whatsapp';

function Sobre() {

    return (
        <div id='sobre' style={{ backgroundImage: "url('../background.png')" }}>


            <Menu></Menu>

            <Container>
                <Row>
                    <Col md={5}>
                        <div style={{ backgroundImage: "url('../sobre.png')" }} className="sobre slide-in-bottom">
                            <h1 className="sobre__title">Nossa História</h1>

                            <p className="sobre__text">Nossa trajetória começou no setor de transporte de automóveis, onde operamos com cegonheiras para grandes players do mercado, como a renomada empresa SADA. A expertise adquirida nesse segmento nos permitiu expandir nossos serviços e nos tornar referência em transportes gerais. A experiência acumulada com grandes volumes e a exigência de alta precisão nos transportes de veículos nos capacitou a oferecer soluções logísticas robustas e confiáveis para diversas indústrias.</p>
                        </div>
                    </Col>

                    <Col md={1}></Col>

                    <Col md={5}>
                        <div id="sobre2" style={{ backgroundImage: "url('../sobre2.png')" }} className="sobre slide-in-bottom">
                            <h1 className="sobre__title">Nossa Missão</h1>

                            <p className="sobre__text">Nossa missão é garantir a excelência no transporte de cargas, oferecendo serviços que agregam valor aos negócios de nossos clientes. Nos comprometemos com a pontualidade, segurança e integridade das mercadorias transportadas, assegurando que cada entrega seja realizada com a máxima eficiência.</p>
                        </div>
                    </Col>

                </Row>
            </Container>

            <Footer></Footer>

            <Whatsapp></Whatsapp>


        </div>
    )
  
}


export default Sobre